import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { protectedRoutes, publicRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import { useSelector } from 'react-redux';


const isAllowed = (currentIndex, maxIndex) => {
	return currentIndex <= maxIndex;
};


const getAllowedRoutes = (routes, currentIndex) => {
	let updatedRoutes = [];

	for (const route of routes) {
		if (isAllowed(currentIndex, route.maxIndex)) {
			updatedRoutes.push(route);
		}
	}

	return updatedRoutes;
};


const Routes = () => {
	const memberRoleIndex = useSelector((state) => state.Member.memberInfo?.memberRole?.orderIndex);
	const allowedRoutes = getAllowedRoutes(protectedRoutes, memberRoleIndex);


	return (
		<RouterRoutes>
			<Route path="/" element={<ProtectedRoute />}>
				<Route path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />
				{allowedRoutes.map((route, index) => {
					return (
						<Route
							key={route.key + index}
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key}
									component={route.component}
									{...route.meta}
								/>
							}
						/>
					)
				})}
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>
			<Route path="/" element={<PublicRoute />}>
				{publicRoutes.map(route => {
					return (
						<Route
							key={route.path}
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key}
									component={route.component}
									{...route.meta}
								/>
							}
						/>
					)
				})}
			</Route>
		</RouterRoutes>
	)
}

export default Routes