import { createSlice } from '@reduxjs/toolkit';
import { PERMISSIONS } from 'constants/AuthConstant';


export const initialState = {
    permissions: JSON.parse(localStorage.getItem(PERMISSIONS)) || [],
    refreshIndex: 0
};


export const permissionSlice = createSlice({
	name: 'Permission',
	initialState,
	reducers: {
        setPermissions: (state, action) => {
			state.permissions = action.payload;
		},
        resetPermissions: (state, action) => {
			state.permissions = [];
		},
        setRefreshIndex: (state, action) => {
			state.refreshIndex = state.refreshIndex + 1;
		},
	},
});


export const { 
    setPermissions,
    resetPermissions,
    setRefreshIndex,
} = permissionSlice.actions;


export default permissionSlice.reducer;