import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';


export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'verify',
        path: `${AUTH_PREFIX_PATH}/email/verify`,
        component: React.lazy(() => import('views/auth-views/authentication/verify-email')),
    },
    {
        key: 'code',
        path: `${AUTH_PREFIX_PATH}/code/confirm`,
        component: React.lazy(() => import('views/auth-views/authentication/set-code')),
    },
];



export const protectedRoutes = [
    {
        maxIndex: 10,
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/main')),
    },
    {
        maxIndex: 10,
        key: 'dashboard.review',
        path: `${APP_PREFIX_PATH}/dashboards/review`,
        component: React.lazy(() => import('views/app-views/dashboards/reviews')),
    },
    {
        maxIndex: 10,
        key: 'dashboard.stats',
        path: `${APP_PREFIX_PATH}/dashboards/stats`,
        component: React.lazy(() => import('views/app-views/dashboards/statistics')),
    },
    {
        maxIndex: 10,
        key: 'dashboard.stats.application',
        path: `${APP_PREFIX_PATH}/dashboards/stats/application`,
        component: React.lazy(() => import('views/app-views/dashboards/statistics/applications')),
    },
    {
        maxIndex: 10,
        key: 'car',
        path: `${APP_PREFIX_PATH}/car`,
        component: React.lazy(() => import('views/app-views/car')),
    },
    {
        maxIndex: 10,
        key: 'car-list',
        path: `${APP_PREFIX_PATH}/car/list`,
        component: React.lazy(() => import('views/app-views/car/car-list')),
    },
    {
        maxIndex: 10,
        key: 'contact-client',
        path: `${APP_PREFIX_PATH}/contact/client`,
        component: React.lazy(() => import('views/app-views/customer-care/contacts')),
    },
    {
        maxIndex: 10,
        key: 'purchase',
        path: `${APP_PREFIX_PATH}/purchase`,
        component: React.lazy(() => import('views/app-views/application')),
    },
    {
        maxIndex: 10,
        key: 'purchase-application',
        path: `${APP_PREFIX_PATH}/purchase/application`,
        component: React.lazy(() => import('views/app-views/application/applications-list')),
    },
    {
        maxIndex: 10,
        key: 'purchase-application-new',
        path: `${APP_PREFIX_PATH}/purchase/application/new`,
        component: React.lazy(() => import('views/app-views/application/new-application')),
    },
    {
        maxIndex: 10,
        key: 'purchase-application-pending',
        path: `${APP_PREFIX_PATH}/purchase/application/pending`,
        component: React.lazy(() => import('views/app-views/application/applications-list/pending-applications')),
    },
    {
        maxIndex: 10,
        key: 'purchase-application-all',
        path: `${APP_PREFIX_PATH}/purchase/application/all`,
        component: React.lazy(() => import('views/app-views/application/applications-list/all-applications')),
    },
    {
        maxIndex: 10,
        key: 'purchase-application-response',
        path: `${APP_PREFIX_PATH}/purchase/response`,
        component: React.lazy(() => import('views/app-views/application/application-responses')),
    },
    {
        maxIndex: 10,
        key: 'purchase-application-schedule',
        path: `${APP_PREFIX_PATH}/purchase/schedule`,
        component: React.lazy(() => import('views/app-views/application/application-schedules')),
    },
    {
        maxIndex: 10,
        key: 'purchase-application-decision',
        path: `${APP_PREFIX_PATH}/purchase/decision`,
        component: React.lazy(() => import('views/app-views/application/application-decisions')),
    },
    {
        maxIndex: 2,
        key: 'administration',
        path: `${APP_PREFIX_PATH}/administration`,
        component: React.lazy(() => import('views/app-views/administration')),
    },
    {
        maxIndex: 2,
        key: 'administration',
        path: `${APP_PREFIX_PATH}/administration/booking/time/list`,
        component: React.lazy(() => import('views/app-views/administration/booking-time')),
    },
    {
        maxIndex: 2,
        key: 'administration.user',
        path: `${APP_PREFIX_PATH}/administration/user`,
        component: React.lazy(() => import('views/app-views/administration/users')),
    },
    {
        maxIndex: 2,
        key: 'administration.user.role',
        path: `${APP_PREFIX_PATH}/administration/user/role/list`,
        component: React.lazy(() => import('views/app-views/administration/users/users-roles')),
    },
    {
        maxIndex: 2,
        key: 'administration.user.permission',
        path: `${APP_PREFIX_PATH}/administration/user/permission/list`,
        component: React.lazy(() => import('views/app-views/administration/users/permissions-list')),
    },
    {
        maxIndex: 2,
        key: 'administration.user.permission.role',
        path: `${APP_PREFIX_PATH}/administration/user/permission/role`,
        component: React.lazy(() => import('views/app-views/administration/users/role-permissions')),
    },
    {
        maxIndex: 2,
        key: 'administration.user.member',
        path: `${APP_PREFIX_PATH}/administration/user/member/list`,
        component: React.lazy(() => import('views/app-views/administration/users/users-list')),
    },
    {
        maxIndex: 10,
        key: 'administration.activity',
        path: `${APP_PREFIX_PATH}/administration/activity`,
        component: React.lazy(() => import('views/app-views/administration/activity')),
    },
    {
        maxIndex: 2,
        key: 'administration.settings.division',
        path: `${APP_PREFIX_PATH}/administration/settings/division`,
        component: React.lazy(() => import('views/app-views/administration/settings/regionals')),
    },
    {
        maxIndex: 2,
        key: 'administration.settings.division.country',
        path: `${APP_PREFIX_PATH}/administration/settings/division/country/list`,
        component: React.lazy(() => import('views/app-views/administration/settings/regionals/country')),
    },
    {
        maxIndex: 2,
        key: 'administration.settings.division.city',
        path: `${APP_PREFIX_PATH}/administration/settings/division/city/list`,
        component: React.lazy(() => import('views/app-views/administration/settings/regionals/city')),
    },
    {
        maxIndex: 2,
        key: 'administration.settings.type',
        path: `${APP_PREFIX_PATH}/administration/settings/type`,
        component: React.lazy(() => import('views/app-views/administration/settings/typologies')),
    },
    {
        maxIndex: 2,
        key: 'administration.settings.type.check',
        path: `${APP_PREFIX_PATH}/administration/settings/type/check/list`,
        component: React.lazy(() => import('views/app-views/administration/settings/typologies/check-typologies')),
    },
    {
        maxIndex: 2,
        key: 'administration.settings.type.application',
        path: `${APP_PREFIX_PATH}/administration/settings/type/application/list`,
        component: React.lazy(() => import('views/app-views/administration/settings/typologies/application-typologies')),
    },
    {
        maxIndex: 2,
        key: 'administration.settings.type.notification',
        path: `${APP_PREFIX_PATH}/administration/settings/type/notification/list`,
        component: React.lazy(() => import('views/app-views/administration/settings/typologies/notification-typologies')),
    },
    {
        maxIndex: 2,
        key: 'administration.user.member',
        path: `${APP_PREFIX_PATH}/administration/user/member/list`,
        component: React.lazy(() => import('views/app-views/administration/users/users-list')),
    },
    {
        maxIndex: 2,
        key: 'administration.settings.status',
        path: `${APP_PREFIX_PATH}/administration/settings/status/list`,
        component: React.lazy(() => import('views/app-views/administration/settings/statuses')),
    },
    {
        maxIndex: 2,
        key: 'administration.settings.mail',
        path: `${APP_PREFIX_PATH}/administration/settings/email`,
        component: React.lazy(() => import('views/app-views/administration/settings/mail-settings')),
    },
    {
        maxIndex: 10,
        key: 'administration.check.point',
        path: `${APP_PREFIX_PATH}/administration/check/point/list`,
        component: React.lazy(() => import('views/app-views/administration/check-point')),
    },
    {
        maxIndex: 2,
        key: 'administration.car',
        path: `${APP_PREFIX_PATH}/administration/car`,
        component: React.lazy(() => import('views/app-views/administration/car')),
    },
    {
        maxIndex: 2,
        key: 'administration.car.brand',
        path: `${APP_PREFIX_PATH}/administration/car/brand`,
        component: React.lazy(() => import('views/app-views/administration/car/users-roles')),
    },
    {
        maxIndex: 2,
        key: 'administration.car.body.type',
        path: `${APP_PREFIX_PATH}/administration/car/type`,
        component: React.lazy(() => import('views/app-views/administration/car/car-types')),
    },
    {
        maxIndex: 2,
        key: 'administration.car.model',
        path: `${APP_PREFIX_PATH}/administration/car/model`,
        component: React.lazy(() => import('views/app-views/administration/car/car-models')),
    },
    {
        maxIndex: 2,
        key: 'administration.car.variant',
        path: `${APP_PREFIX_PATH}/administration/car/variant`,
        component: React.lazy(() => import('views/app-views/administration/car/car-variants')),
    },
    {
        maxIndex: 2,
        key: 'administration.car.color',
        path: `${APP_PREFIX_PATH}/administration/car/color`,
        component: React.lazy(() => import('views/app-views/administration/car/car-colors')),
    },
    {
        maxIndex: 10,
        key: 'docs.documentation',
        path: `${APP_PREFIX_PATH}/docs/documentation/*`,
        component: React.lazy(() => import('views/app-views/docs')),
    },
    {
        maxIndex: 10,
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('views/app-views/profile/my-profile')),
    },
    {
        maxIndex: 10,
        key: 'notification',
        path: `${APP_PREFIX_PATH}/notification`,
        component: React.lazy(() => import('views/app-views/profile/my-notifications')),
    },
    {
        maxIndex: 10,
        key: 'password',
        path: `${APP_PREFIX_PATH}/password`,
        component: React.lazy(() => import('views/app-views/profile/my-password')),
    },
];