// 1. BACKEND BASE URL
// export const BACKEND_BASE_URL = "http://192.168.100.4:4000"
// export const BACKEND_BASE_URL = "https://wbac.onrender.com"
export const BACKEND_BASE_URL = "https://nbcmbackend.onrender.com"


// 2. AUTHENTICATION
export const SIGN_IN = "/api/hr/login/"
export const SIGN_OUT = "/api/hr/logout/"
export const VERIFY_EMAIL = "/api/hr/member/email/verify/"
export const CONFIRM_CODE = "/api/hr/member/code/confirm/"
export const CHANGE_PASSWORD = "/api/hr/member/password/change/"


// 3. NOTIFICATION
export const MY_NOTIFICATIONS = "/api/core/notification/my/preview/"
export const MY_ALL_NOTIFICATIONS = "/api/core/notification/list/"
export const SET_NOTIFICATION_SEEN = "/api/core/notification/seen/set/"
export const CONTACT = "/api/core/access/contact/"


// 4. ADMINITRATION
export const STATUS = "/api/core/settings/status/list/";
export const STATUS_SIMPLE = "/api/core/settings/status/simple/";
export const CHECK_TYPE = "/api/core/settings/check/type/list/";
export const CHECK_TYPE_SIMPLE = "/api/core/settings/check/type/simple/";
export const NOTIFICATION_TYPE = "/api/core/notification/type/list/";
export const ACTIVITY = "/api/core/access/activity/";
export const ACTIVITY_RESET = "/api/core/access/activity/reset/";
export const COUNTRY = "/api/core/division/country/list/";
export const COUNTRY_SMIPLE = "/api/core/division/country/simple/";
export const CITY = "/api/core/division/city/list/";
export const CITY_SIMPLE = "/api/core/division/city/simple/";
export const CHECK_POINT = "/api/core/main/check/point/list/";
export const CHECK_POINT_SIMPLE = "/api/core/main/check/point/simple/";
export const MAIL_SETTING = "/api/core/settings/mail/list/";
export const BODY_TYPE_SIMPLE = "/api/core/car/body/type/simple/";
export const BODY_TYPE = "/api/core/car/body/type/list/";
export const BRAND_SIMPLE = "/api/core/car/brand/simple/";
export const BRAND = "/api/core/car/brand/list/";
export const MODEL_SIMPLE = "/api/core/car/model/simple/";
export const MODEL = "/api/core/car/model/list/";
export const VARIANT_SIMPLE = "/api/core/car/variant/simple/";
export const VARIANT = "/api/core/car/variant/list/";
export const CAR_SIMPLE = "/api/core/car/car/simple/";
export const CAR = "/api/core/car/car/list/";
export const APPLICATION_TYPE = "/api/core/settings/application/type/list/";
export const APPLICATION_TYPE_SIMPLE = "/api/core/settings/application/type/simple/";
export const BOOKING_TIME_SIMPLE = "/apir/core/settings/booking/time/simple/";
export const BOOKING_TIME = "/api/core/settings/booking/time/list/";
export const COLOR_SIMPLE = "/apir/core/settings/color/simple/";
export const COLOR = "/api/core/settings/color/list/";


// 6. MEMBER
export const MEMBER = "/api/hr/member/list/"
export const MEMBER_SIMPLE = "/api/hr/member/simple/"
export const MEMBER_ROLE = "/api/hr/member/role/list/"
export const MEMBER_ROLE_SIMPLE = "/api/hr/member/role/simple/"
export const MEMBER_DETAIL = "/api/hr/member/list/"
export const RESET_MEMBER_PASSWORD = "/api/hr/member/password/reset/"
export const CHANGE_MEMBER_PASSWORD = "/api/hr/member/password/change/"


// 7. APPLICATION
export const APPLICATION = "/api/application/purchase/list/"
export const PENDING_APPLICATION = "/api/application/purchase/pending/"
export const APPLICATION_PDF = "/api/application/purchase/code/"
export const PURCHASE_RESPONSE = "/api/response/purchase/response/"
export const PURCHASE_SCHEDULE = "/api/schedule/purchase/schedule/"
export const PURCHASE_DECISION = "/api/decision/purchase/decision/"


// 8. STATISTICS
export const APPLICATION_STATISTICS = "/api/report/application/stats/application/"
export const DASHBOARD_STATISTICS = "/api/report/dashboard/stats/counter/"
export const REVIEW = "/api/report/review/list/"


// 9. PERMISSIONS
export const PERMISSION = "/api/core/access/permission/"
export const PERMISSIONS_TREE_LIST = "/api/core/access/permissions/tree/"
export const ADD_PERMISSION = "/api/core/access/permission/"
export const PERMISSIONS_TREE = "/api/core/access/permissions/tree/list/"
export const ROLE_PERMISSIONS_UPDATE = "/api/core/access/permissions/role/update/"
export const ROLE_PERMISSIONS_TREE = "/api/core/access/permissions/role/tree/"
export const MY_PERMISSIONS_TREE = "/api/core/access/my/permissions/"
export const MY_PERMISSIONS = "/api/core/access/my/permissions/"