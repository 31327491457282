import { createSlice } from '@reduxjs/toolkit';
import { MEMBER_INFO } from 'constants/AuthConstant';


export const initialState = {
    memberInfo: JSON.parse(localStorage.getItem(MEMBER_INFO)) || null,
    refreshIndex: 0
};


export const memberSlice = createSlice({
	name: 'Member',
	initialState,
	reducers: {
        setMemberInfo: (state, action) => {
			state.memberInfo = action.payload;
		},
        resetMemberInfo: (state, action) => {
			state.memberInfo = null;
		},
        setRefreshIndex: (state, action) => {
			state.refreshIndex = state.refreshIndex + 1;
		},
        updateProfile: (state, action) => {
			state.memberInfo.avatar = action.payload.avatar;
            state.refreshIndex = state.refreshIndex + 1;
		},
	},
});


export const { 
    setMemberInfo,
    resetMemberInfo,
    setRefreshIndex,
    updateProfile
} = memberSlice.actions;


export default memberSlice.reducer;